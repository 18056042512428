.enrollManageBox {

	.examBox {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 26px;
		box-sizing: border-box;
		padding-bottom: 20px;
	}

	.examItem {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
		border-radius: 20px;
		box-sizing: border-box;
		padding: 20px;
		margin-top: 26px;
	}

	.examItem img {
		width: 100%;
		height: 306px;
		border-radius: 20px;
	}

	.examInner {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-top: 26px;
		padding-bottom: 15px;
	}

	.examInner .icon {
		margin-right: 10px;
	}

	.examTitle {
		font-size: 28px;
		font-weight: bold;
		color: #5a5a5a;
		margin: 0;
	}

	.examAddress {
		margin: 0;
		margin-top: 10px;
		font-size: 20px;
		font-weight: bold;
		color: #8c9abd;
	}

	.examDate {
		margin: 0;
		margin-top: 10px;
		font-size: 20px;
		color: #b9b9b9;
	}

	.examBtn {
		width: 180px;
		height: 60px;
		background: #4096f7;
		border-radius: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: bold;
		font-size: 24px;
		cursor: pointer;
	}

	.examData {
		display: flex;
		flex-direction: column;
		padding-top: 5px;
		border-top: 1px solid #eee;
		width: 100%;

		.examDataTitle {
			color: rgb(165, 165, 165);
			margin: 10px 0;
		}

		.examDataItem {
			margin-bottom: 10px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}

		.examDataInfoNone {
			opacity: 0;
		}
	}

	.pickerSelect {
		width: 100%;
		padding: 0 26px;
		box-sizing: border-box;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-top: 20px;
		font-size: 28px;
		color: #4e4e4e;
		font-weight: bold;

		p {
			margin-right: 14px;
		}
	}

}