.publishExamList {
	.examBoxNoThing {
		margin-top: 60px;
		text-align: center;
	}

	&.publishExam {
		background: #fff;
		padding-top: 74px;
	}

	&.isExamine {
		padding: 0;
	}

	.isExamine .examBox {
		padding-bottom: 36px;
	}

	.adm-tabs-tab {
		font-size: 26px;
	}

	.topTabs {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		height: 74px;
		z-index: 6;
		background: #fff;
	}

	.addExam {
		position: fixed;
		bottom: 6%;
		left: 50%;
		transform: translateX(-50%);
		background: #4096fe;
		height: 80px;
		padding: 0 60px;
		border-radius: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 28px;
		font-weight: bold;
	}

	.examBox {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 26px;
		box-sizing: border-box;
		padding-bottom: 190px;
	}

	.examItem {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
		border-radius: 20px;
		box-sizing: border-box;
		padding: 20px;
		margin-top: 26px;
	}

	.examItem img {
		width: 100%;
		height: 306px;
		border-radius: 20px;
	}

	.examInner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 16px;
	}

	.examInner .icon {
		margin-right: 10px;
	}

	.examTitle {
		font-size: 28px;
		font-weight: bold;
		color: #5a5a5a;
		margin-bottom: 10px;
	}

	.examAddress {
		margin: 0;
		margin-top: 10px;
		font-size: 22px;
		font-weight: bold;
		color: #8c9abd;
	}

	.examDate {
		margin: 0;
		margin-top: 10px;
		font-size: 22px;
		color: #767676;
	}

	.examBtn {
		width: 200px;
		height: 62px;
		background: #4096f7;
		border-radius: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: bold;
		font-size: 24px;
		cursor: pointer;
	}

	.newsItem {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
		border-radius: 20px;
		box-sizing: border-box;
		padding: 20px;
		margin-top: 26px;
	}

	.newsTitle {
		font-size: 30px;
		font-weight: bold;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.adminNewsData {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.adminNewsInfo {
		width: 70%;
		margin-top: 20px;
	}

	.newsInfoItem {
		font-size: 26px;
		color: rgb(113, 113, 113);
		margin-bottom: 10px;
	}

	.newsInfoItem_news {
		color: #4096fe;
	}

	.newsInfoItem_banner {
		color: #4fb82c;
	}

	.newsItemImg {
		width: 100%;
		height: 300px;
		border-radius: 20px;
		margin-top: 20px;
	}

	.examInfo {
		width: 70%;
	}

	.examineBtn {
		background: #4096fe;
		width: 140px;
		height: 60px;
		border-radius: 1000px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: bold;
		font-size: 26px;
		cursor: pointer;
	}
}