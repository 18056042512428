.content {
	padding: 0 26px !important;
	box-sizing: border-box;
}

.index {
	padding-bottom: 20px;
}

.indexSwiperBox {
	width: 100%;
	position: relative;
	height: 336px;
}

.indexSwiperBox .indexItem {
	box-sizing: border-box;
	width: 686px;
	height: 336px;
	border-radius: 20px;
	box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.16);
	overflow: hidden;
	margin: 0 auto;
	position: relative;
}

.indexItem img {
	height: 100%;
	width: 100%;
}

.swiperShadow {
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 10;
	background: linear-gradient(180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.09) 63%,
			rgba(0, 0, 0, 0.8) 100%);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	padding: 0 18px;
	box-sizing: border-box;
	line-height: 46px;
	padding-bottom: 20px;
}

.swiperShadow .swiperTitle {
	color: #fff;
	font-size: 30px;
	font-weight: bold;
}

.swiperShadow .swiperTime {
	font-size: 20px;
	color: #d0d0d0;
}

.quickLink {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
	padding: 0 0px;
	width: 100%;
	box-sizing: border-box;
	margin-top: 68px;
}

.quickLinkItem {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 20%;
}


.quickLinkIcon {
	font-size: 83px;
}

.quickLinkText {
	font-size: 24px;
	color: #656565;
	margin-top: 12px;
}

.certQuery {
	margin-top: 24px;
}

.certQuery .quickLink {
	margin-top: 26px;
	padding: 0;
}

.title {
	padding-left: 16px;
	position: relative;
	font-size: 28px;
	color: #4a4545;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.title::before {
	content: " ";
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 6px;
	height: 18px;
	background: linear-gradient(180deg, #77a9d5 0%, #4ffae0 100%);
	border-radius: 200px;
}

.titleMore {
	color: #898989;
	font-size: 22px;
}

.newsExamInner {
	margin-top: 18px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.noDataExam {
		width: 100%;
		text-align: center;
		color: #828282;
	}
}

.newsExamInnerItem {
	width: 340px;
	height: 100%;
	background: #ffffff;
	box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.03);
	border-radius: 20px;
	padding: 12px;
	box-sizing: border-box;
}

.newsExamInnerItem img {
	width: 100%;
	border-radius: 20px;
	height: 180px;
}

.newsExamInnerItem .stationName {
	color: #334f7e;
	font-size: 24px;
	font-weight: bold;
	margin: 0;
	margin-top: 18px;
}

.newsExamInnerItem .stationAddress {
	color: #334f7e;
	font-size: 20px;
	margin: 0;
	margin-top: 8px;
}

.newsInfo {
	margin-top: 50px;
}

.newsInfoInner {
	margin-top: 18px;
	background: #ffffff;
	box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.01);
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	padding: 28px 18px;
	box-sizing: border-box;

	.noDataNew {
		width: 100%;
		text-align: center;
		color: #828282;
	}
}

.newsInfoInner p {
	margin: 0;
}

.newsInfoInnerItem {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
}

.newsInfoInnerItem:last-of-type {
	margin-bottom: 0;
}

.newsInfoInnerItem .newsInfoTitle {
	font-size: 23px;
	font-weight: bold;
	color: #454545;
	margin: 0;
	line-height: 40px;
}

.newsSubInfo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #a3a3a3;
	font-size: 18px;
	margin-top: 16px;
}

.adm-swiper-item {
	display: flex;
	justify-content: center;
	align-items: center;
}
.telItem{
	line-height: 50px;
	margin-bottom: 10px;
}