.studentList{
	padding: 26px;
	box-sizing: border-box;
	width: 100%;
}
.studentListItem{
	overflow: hidden;
	border-radius: 20px;
	height: 240px;
	margin-bottom: 20px;
	box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
}
.studentItem{
	background: #fff;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
	height: 240px;
}
.studentItem .studentLeft{
	height: 100%;
	width: 168px;
}
.studentLeft img{
	width: 100%;
	height: 100%;
	border: none;
	outline: none;
}
.studentRight{
	flex: 1;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	height: 100%;
	padding: 6px 0 6px 26px;
	box-sizing: border-box;
	color: rgb(110, 110, 110);
	font-size: 24px;
}