.examBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 26px;
  box-sizing: border-box;
  padding-bottom: 190px;
}

.examItem {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 26px;
}

.examItem img {
  width: 100%;
  height: 306px;
  border-radius: 20px;
}

.examInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.examInner .icon {
  margin-right: 10px;
}

.examTitle {
  font-size: 28px;
  font-weight: bold;
  color: #5a5a5a;
  margin-bottom: 10px;
}

.examAddress {
  margin: 0;
  margin-top: 10px;
  font-size: 22px;
  font-weight: bold;
  color: #8c9abd;
}

.examDate {
  margin: 0;
  margin-top: 10px;
  font-size: 22px;
  color: #767676;
}
