.authRole {
	padding-top: 20px;

	.examBoxNoThing {
		text-align: center;
		margin-top: 60px;
	}

	.authRoleItem {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
		border-radius: 20px;
		box-sizing: border-box;
		padding: 20px;
		margin-bottom: 20px;
	}

	.examine {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 200px;
	}

	.examBox {
		padding-bottom: 36px;
	}

	.examineImg {
		height: 100%;
		border-radius: 20px;
		overflow: hidden;
	}

	.examineImg img {
		width: 160px;
		height: 100%;
	}

	.examineData {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex: 1;
		height: 100%;
		padding-left: 16px;
		box-sizing: border-box;
	}

	.examineTitle {
		font-weight: bold;
		font-size: 28px;
		margin-bottom: 20px;
	}

	.examineInfoItem {
		display: flex;
		flex-direction: column;
	}

	.examineInfoItem span {
		line-height: 40px;
	}

	.examineInfo {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		flex-direction: column;
	}

	.institution {
		display: flex;
		flex-direction: column;
	}

	.institutionTitle {
		font-weight: bold;
		font-size: 28px;
		color: rgb(58, 58, 58);
		margin-bottom: 20px;
	}

	.institutionImg {
		height: 260px;
		width: 100%;
		border-radius: 20px;
		overflow: hidden;
		margin-bottom: 20px;
	}

	.institutionImg img {
		width: 100%;
		height: 100%;
	}

	.institutionData {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.institutionInfo {
		width: 70%;
		display: flex;
		flex-direction: column;
	}

	.institutionInfo span {
		line-height: 46px;
		color: rgb(109, 109, 109);
	}

	.examineBtn {
		background: #4096fe;
		width: 140px;
		height: 60px;
		border-radius: 1000px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: bold;
		font-size: 26px;
		cursor: pointer;
	}
}