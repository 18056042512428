@import url("../../../pages/report/report.css");
.quill {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 3;
  box-sizing: border-box;
}
.saveBtn {
  position: fixed;
  right: 10px;
  top: 30px;
  width: 130px;
  height: 60px;
  background: #4096fe;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 1000px;
  z-index: 20;
  cursor: pointer;
}
#addNewsImg {
  opacity: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}
.editLink {
  color: #4096fe;
  border-bottom: 1px solid #4096fe;
  display: inline-block;
}
.deleteNews {
  margin-top: 30px;
}
.ql-container {
  padding-bottom: 60px !important;
  box-sizing: border-box !important;
}
.editDeleteBtn {
  margin: 20px 0;
  cursor: pointer;
}
.noDataNew {
  color: #eee;
}
