.report {
  padding-bottom: 30px;
}
.adm-list-item {
  --prefix-width: 4.8em !important;
}
.adm-list {
  --font-size: var(--adm-font-size-6);
}
.adm-input,
.adm-text-area-element {
  --font-size: var(--adm-font-size-6);
}
.adm-list-body {
  box-shadow: 0 0 0.4rem 0.01rem rgba(0, 0, 0, 0.016);
}
.adm-checkbox {
  margin-top: 20px;
}
.adm-checkbox-content {
  font-size: 26px;
}
.adm-checkbox {
  --icon-size: 30px;
}
.reportProtocol {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.reportProtocol .examinerLink,
.verityBtn {
  color: #4096fe;
  cursor: pointer;
}

.adm-radio-icon {
  width: 30px !important;
  height: 30px !important;
}
.adm-radio-content {
  font-size: 30px !important;
}
.adm-list-item-content-main,
.adm-form-item-child-inner {
  overflow: hidden;
}
.agree {
  margin: 24px;
  box-sizing: border-box;
  background: #fff;
  padding-left: 16px;
  padding-right: 12px;
  box-shadow: 0 0 0.4rem 0.01rem rgba(0, 0, 0, 0.016);
  border-radius: 16px;
  height: 88px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.agree .adm-checkbox {
  margin-top: 0;
  margin-right: 20px;
}
.agree p {
  border-bottom: 1px solid #4096fe;
  color: #4096fe;
}
.deleteStuInfo {
  line-height: 60px;
  text-align: center;
  color: #333;
  font-size: 28px;
}
