.stationBox {
	width: 100%;
	padding: 26px;
	box-sizing: border-box;

	.authRoleItem {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
		border-radius: 20px;
		box-sizing: border-box;
		padding: 20px;
		margin-bottom: 20px;

		.institution {
			display: flex;
			flex-direction: column;
		}

		.institutionTitle {
			font-weight: bold;
			font-size: 28px;
			color: rgb(58, 58, 58);
			margin-bottom: 20px;
		}

		.institutionImg {
			height: 260px;
			width: 100%;
			border-radius: 20px;
			overflow: hidden;
			margin-bottom: 20px;
		}

		.institutionImg img {
			width: 100%;
			height: 100%;
		}

		.institutionData {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.institutionInfo {
			width: 70%;
			display: flex;
			flex-direction: column;
		}

		.institutionInfo span {
			line-height: 46px;
			color: rgb(109, 109, 109);
		}
	}

	.noStation {
		width: 100%;
		text-align: center;
		margin-top: 30px;
		color: rgb(120, 120, 120);
	}
}