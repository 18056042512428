.newsDetail{
	width: 100%;
	padding: 0 26px;
	box-sizing: border-box;
	padding-bottom: 36px;
	padding-top: 20px;
}
.newsDetail p{
	margin: 0;
}
.newsDetail img{
	width: 100%;
	height: 322px;
	border-radius: 18px;
	margin-top: 20px;
}
.newsDetailTitle{
	font-size: 32px;
	font-weight: bold;
	color: #454545;
	margin-bottom: 24px;
}
.newsDetailAddress,.newsDetailDate{
	color: #A3A3A3;
	font-size: 22px;
	margin-bottom: 12px;
}
.newsDetailInner{
	line-height: 50px;
	color: #454545;
	margin-top: 30px;
}