.uploaderImgList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
}
.uploaderImgItem {
  width: 160px;
  height: 160px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-right: 20px;
  flex-shrink: 0;
}
.uploaderImgItem img {
  height: 100%;
  width: 100%;
}
.uploaderImgItemAdd {
  width: 160px;
  height: 160px;
  border-radius: 10px;
  background: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploaderImgItemAdd .addIcon {
  font-size: 60px;
  color: rgb(174, 174, 174);
}
.deleteBtn {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(255, 235, 235, 0.8);
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 0 100px;
  font-size: 30px;
  cursor: pointer;
}
.deleteBtn .deleteIcon {
  flex-shrink: 0;
  transform: translate(3px, -2px);
  color: rgb(222, 102, 102);
}
.imgSub {
  width: 160px;
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  color: #a7a7a7;
}
.uploadItem {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  margin-right: 20px;
}
.uploadInput {
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 6;
}
