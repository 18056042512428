.agreeBtn{
	width: 340px;
	height: 90px;
	border-radius: 20px;
	background: #4096fe;
	margin: 80px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-weight: bold;
	font-size: 26px;
}
.agreeTiming{
	background: #ccce;
}