.searchSchool {
	.searchList {
		max-height: 50vh;
		min-height: 20vh;
		overflow-y: auto;
	}

	.searchPopBox {
		padding: 26px;
		box-sizing: border-box;
	}

	.adm-list-item {
		width: auto !important;
	}
}