.examDetail {
	width: 750px;
	padding: 26px;
	padding-bottom: 0;
	box-sizing: border-box;
}



.examBtnBox {
	width: 35%;
	display: flex;
	justify-content: flex-end;
	margin-top: 0.1rem;
	flex-wrap: wrap;

	.examBtn {
		margin-top: 0.1rem;

	}
}


.banner {
	width: 100%;
	height: 306px;
	border-radius: 20px;
}

.info {
	width: 100%;
	background: #ffffff;
	box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.01);
	border-radius: 20px;
	margin-top: 26px;
	padding: 24px;
	box-sizing: border-box;
}

.examDetailTitle {
	font-size: 30px;
	font-weight: bold;
	color: #333333;
	margin-bottom: 24px;
}

.examDetailInfo {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.infoItem {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 16px;
	color: #898989;
	font-size: 22px;
	line-height: 40px;
}

.infoValue {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.infoValue .tag {
	padding: 0 16px;
	height: 36px;
	border-radius: 8px;
	border: 1px solid #ee6f21;
	color: #ee6f21;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
}

.examiner {
	background: #ffffff;
	box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.01);
	border-radius: 20px;
	margin-top: 26px;
	padding: 24px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 66px;
}

.examinerItem {
	width: 164px;
	margin-bottom: 30px;
}

.examinerItem img {
	width: 100%;
}

.examinerName {
	font-size: 26px;
	font-weight: bold;
	color: #333333;
	margin: 0;
	margin-top: 4px;
}

.examinerTitle {
	font-size: 20px;
	color: #585858;
	margin: 0;
	margin-top: 10px;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.last {
	margin-bottom: 0;
}

.examinerBlockTitle {
	font-size: 30px;
	font-weight: bold;
	color: #333333;
	width: 100%;
	margin: 0;
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;

	.adm-dropdown-item {
		margin-left: 20px;
	}
}

.detailBottomBarSafe {
	width: 100%;
	height: 0px;
	padding-bottom: constant(safe-area-inset-bottom);
	padding-bottom: env(safe-area-inset-bottom);
	background: #fff;
}

.bottomBlockInner {
	width: 100%;
	min-height: 100px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 24px;
	box-sizing: border-box;
	background: #fff;
}

.bottomBlockInner p {
	margin: 0;
}

.bottomBarBlock {
	display: flex;
	flex-direction: column;
}

.bottomInner {
	background: #fff;
	box-shadow: 0px 6px 12px 2px rgba(0, 0, 0, 0.37);
	border-radius: 20px;
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	min-height: 130px;
	display: flex;
	flex-direction: column;
	z-index: 9999999999;
}

.bottomBlockInner .btn {
	width: 200px;
	height: 62px;
	background: #4096f7;
	border-radius: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 24px;
	font-weight: bold;
	cursor: pointer;
}

.price {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-content: space-between;
}

.price .priceName {
	color: #797979;
	font-size: 22px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.price .num {
	font-size: 32px;
	font-weight: bold;
	color: #5794f0;
}

.btnBox {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	position: relative;

	span {
		color: rgb(113, 113, 113);
		font-size: 24px;
		margin-top: 10px;
		position: absolute;
		bottom: -70%;
		left: 50%;
		transform: translateX(-50%);
		display: inline-block;
		white-space: nowrap;
	}
}

.examinerNothing {
	text-align: center;
	width: 100%;
	color: #b9b9b9;
	line-height: 160px;
}

.subTitle {
	margin-top: 30px;
	margin-bottom: 30px;
	color: rgb(135, 135, 135);
}

.reportDetail {
	padding: 26px;
	box-sizing: border-box;
}

.reportDetailTitle {
	margin-bottom: 30px;
}

.examReportNoThing {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 2px dashed #4096fe;
	border-radius: 14px;
	height: 70px;
	color: #4096fe;
	width: 180px;
	margin-top: 30px;
}

.examReportNoThing .icon {
	font-size: 26px;
	margin-right: 6px;
}

.adminBtn,
.adminBtnOther {
	margin: 20px 0 36px 0;
}

.applyList {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;

	.applyListNoThing {
		line-height: 120px;
		width: 100%;
		text-align: center;
		color: rgb(140, 140, 140);
	}

	.applyItemBox {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 36px;
		padding-bottom: 36px;
		border-bottom: 1px solid rgb(242, 242, 242);

		.scoreItemBox {
			display: flex;
			flex-direction: column;
			width: 100%;

			.scoreItem {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 20px;
				color: rgb(144, 144, 144);

				.scoreNum {
					font-weight: bold;
					color: #333333;
				}
			}
		}

		.applyItem {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			height: 160px;
			width: 100%;

			.applyLeft {
				width: 140px;
				height: 100%;

				img {
					width: 100%;
					height: 100%;
					border-radius: 20px;
				}
			}

			.applyRight {
				padding-left: 20px;
				box-sizing: border-box;
				height: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex: 1;

				.applyBtn {
					background: #4096fe;
					height: 50px;
					width: 120px;
					border-radius: 1000px;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					cursor: pointer;
				}

				.applyInfo {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;



					.applyInfoItem {
						font-size: 24px;
						color: rgb(97, 97, 97);

						&.applyInfoName {
							font-weight: bold;
							font-size: 26px;
							color: rgb(65, 65, 65);
						}
					}
				}
			}
		}
	}



}

.examDetailPopBody {
	.adm-popup-body {
		width: 698px;
		margin-left: 26px;
		box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.03);

		.radioBox {
			padding: 16px 26px 26px;
		}
	}

	.adm-popup {
		.adm-dropdown-popup-mask {
			background-color: rgba(0, 0, 0, 0) !important;
		}
	}
}

.downloadCrt {
	width: 100%;
	background-color: #4096fe;
	height: 80px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-weight: bold;
	font-size: 26px;
	margin-bottom: 60px;
}

.bottom {
	position: relative;

	.bottomExaminerBtn {
		position: fixed;
		left: 50%;
		transform: translateX(-50%);
		bottom: 60px;

		.btn {
			background: #4096fe;
			width: 330px;
			height: 80px;
			border-radius: 1000px;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #fff;
			cursor: pointer;
		}
	}
}

.uploadTmp {
	position: fixed;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	opacity: 0;
	overflow: hidden;
}

.downloadTmpBtn {
	margin-bottom: 60px;
	cursor: pointer;
}

.noStudent {
	color: #8e8e8e;
}