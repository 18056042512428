.round-rect-button {
    margin: 0;
    font-size: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: transparent;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    overflow: hidden;
}


.semi-circle {
    margin: 0;

    width: 44px;
    /* 半圆的直径 */
    height: 88px;
    border: 1px solid #007bff;
    /* 半圆的高度 */
    border-top-left-radius: 20px;
    /* 左上角的边界半径 */
    border-bottom-left-radius: 20px;
    /* 左下角的边界半径 */
    background-color: #007bff;
}

.rectangle {
    font-size: 20px;
    margin: 0;
    height: 88px;
    width: 120px;
    background-color: #007bff;
    padding: 0 20px;
    display: flex;
    align-items: center;
}

.rectangle span {
    background-color: transparent;
    width: 100px;
    position: absolute;
    left: 45px;
    color: white;
    font-size: 23px;
}



#fixedButton {
    position: fixed;
    /* 固定定位 */
    bottom: 300px;
    /* 距离底部的距离，可根据需要调整 */
    right: -20px;
    /* 距离右侧的距离，可根据需要调整 */
    z-index: 1000;
    /* 确保按钮在最上层，不会被其他元素遮挡 */
    padding: 10px 20px;
    /* 按钮大小和内边距，可自定义 */
    font-size: 16px;
    /* 字体大小 */
    color: white;
    width: 180px;
    height: 88px;
    /* 文本颜色 */
    background-color: transparent ;
    /* 背景颜色，这里使用了Bootstrap的primary色 */
    border: none;
    /* 移除边框 */
    cursor: pointer;
    /* 鼠标悬停时显示手指图标 */
    border-radius: 5px;
    /* 圆角效果 */
}