.myExamList {
	.adm-dialog {
		z-index: 100;
	}

	&.publishExam {
		width:90%;
		margin: 0 auto;
		background: #fff;
		// padding-top: 74px;
	}

	.adm-tabs-tab {
		font-size: 26px;
	}

	.topTabs {
		position: fixed;
		width: 100%;
		top: 0;
		left: 0;
		height: 74px;
		z-index: 6;
		background: #fff;
	}

	.addExam {
		position: fixed;
		bottom: 10%;
		left: 50%;
		transform: translateX(-50%);
		background: #4096fe;
		height: 80px;
		padding: 0 60px;
		border-radius: 100px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-size: 28px;
		font-weight: bold;
	}

	.examBox {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 26px;
		box-sizing: border-box;
		padding-bottom: 60px;
	}

	.examItem {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
		border-radius: 20px;
		box-sizing: border-box;
		padding: 20px;
		margin-top: 26px;
	}

	.examImgBox {
		position: relative;
	}

	.examImgBox .imgMask {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 5;
		background: rgba(255, 255, 255, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.imgMaskInner {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	.imgMask .icon {
		font-size: 90px;
		margin-bottom: 20px;
	}

	.imgMaskInnerSuccess p {
		color: rgb(77, 223, 33);
		font-weight: bold;
		font-size: 26px;
	}

	.imgMaskInnerError {
		color: rgb(237, 47, 47);
		font-weight: bold;
		font-size: 26px;
	}

	.examItem img {
		width: 100%;
		height: 306px;
		border-radius: 20px;
	}

	.examInner {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 26px;
	}

	.examInner .icon {
		margin-right: 10px;
	}

	.examTitle {
		font-size: 28px;
		font-weight: bold;
		color: #5a5a5a;
		margin: 0;
	}

	.examAddress {
		margin: 0;
		margin-top: 10px;
		font-size: 20px;
		font-weight: bold;
		color: #8c9abd;
	}

	.examDate {
		margin: 0;
		margin-top: 10px;
		font-size: 20px;
		color: #b9b9b9;
	}

	.studentExamBtn {
		width: 200px;
		height: 62px;
		background: #c6c6c6;
		border-radius: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #fff;
		font-weight: bold;
		font-size: 24px;
		cursor: pointer;
	}

	.examBtnActive {
		background: #4096f7 !important;
	}

	.evaluate {
		display: flex;
		flex-direction: column;
		margin-top: 30px;
		padding-right: 30px;
		box-sizing: border-box;
	}

	.evaluateItem {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 10px 0;
	}

	.evaluateItem .label {
		font-size: 26px;
		width: 130px;
		color: rgb(87, 87, 87);
		flex-shrink: 0;
	}

	.evaluateItem2 {
		margin-top: 30px;
		align-items: flex-start;
	}

	.adm-text-area-element {
		border: 1px solid #eee;
		border-radius: 10px;
		padding: 10px 20px;
		box-sizing: border-box;
	}

	.examBoxNoThing {
		width: 100%;
		text-align: center;
		margin-top: 60px;
		color: #717171;
	}

	.line {
		color: #4096fe;
		border-bottom: 1px solid #4096fe;
		margin-right: 30px;
		cursor: pointer;
	}

	.division {
		margin: 30px 0;
		width: 100%;
		height: 1px;
		background: #eee;
	}
}

.examScoreList {
	background: #fff;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	padding: 20px;
	border-radius: 20px;
	width: 420px;

	.examScoreTitle {
		text-align: center;
		line-height: 50px;
		font-weight: bold;
		color: rgb(66, 66, 66);
		font-size: 32px;
	}

	.examScoreBtn {
		margin-top: 36px;
		cursor: pointer;
	}

	p {
		font-size: 26px;
		color: #717171;
		margin-bottom: 20px;
	}
}

.renderFooter {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 30px;

	.renderFooterInfo {
		height: 60px;
		padding: 0 30px;
		background: #353535;
		color: #eee;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 1000px;
	}
}

.subTitle{
	margin:20px
}

.verifyList{
	
}