.adm-calendar-cell-top {
  font-size: 28px !important;
}
.header {
  width: 750px;
  padding: 26px;
  box-sizing: border-box;
  background: #fdfdfd;
  position: fixed;
  top: -2px;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerInner {
  width: 750px;
  height: 112px;
}
.headerInnerDrop {
  height: 179px !important;
}
.headerInnerDrop .header {
  background: #fff !important;
  box-shadow: 0px 6px 20px 2px rgba(0, 0, 0, 0.01);
  border-radius: 0px 0px 40px 40px;
  padding-bottom: 0;
}
.city {
  font-size: 24px;
  color: #5f5f5f;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.city .cityName {
  margin-right: 6px;
  font-weight: bold;
  font-size: 24px;
}
.city .icon {
  font-size: 16px;
}
.search {
  width: 698px;
  height: 60px;
  border-radius: 100px;
  background: #f2f2f2;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
  box-sizing: border-box;
  color: #9f9f9f;
}
.search .icon {
  font-size: 28px;
}
.search input {
  width: 100%;
  height: 100%;
  padding: 0;
  background: transparent;
  border: none;
  padding: 0 10px;
  font-size: 22px;
  outline: none;
}
.drop {
  padding: 30px 22px 0px 22px;
}
.adm-dropdown-nav {
  justify-content: space-between;
}
.adm-dropdown-item {
  flex-shrink: 0 !important;
  flex: none;
}
.adm-dropdown-item-title {
  padding: 0 0 26px 0 !important;
  font-size: 23px !important;
  font-weight: bold;
  color: #656565;
}
.adm-dropdown-popup-body {
  border-radius: 0px 0px 20px 20px;
  overflow: hidden;
}
.adm-dropdown-nav {
  border-bottom: none !important;
}
.areaDrop {
  width: 100%;
}
.areaDrop .adm-tabs-content {
  overflow: auto;
  height: 600px;
}
