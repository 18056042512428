.bottomBarSafe {
  width: 100%;
  height: 0px;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
}
.bottomBarBlock {
  width: 100%;
}
.bottomBarBlockInner {
  min-height: 100px;
}
.bottomBar {
  width: 100%;
}
.bottomBarInner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 6px 12px 2px rgba(0, 0, 0, 0.37);
  border-radius: 20px 20px 0px 0px;
  z-index: 999;
}
.bottomBox {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 100px;
}
.bottomItem {
  font-size: 56px;
  color: rgb(92, 92, 92);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bottomItem p {
  font-size: 20px;
  margin: 0;
}
.bottomItem.active {
  color: #4b62b9;
}
