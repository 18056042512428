.newsList{
	width: 750px;
	padding: 0 26px;
	box-sizing: border-box;
}
.newsList p{
	margin: 0;
}
.backBtn{
	padding: 22px 0;
	display: inline-block;
}
.newsListContent{
	background: #FFFFFF;
	box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.01);
	border-radius: 12px;
	padding: 26px;
	box-sizing: border-box;
	width: 100%;
}
.newsInfoInnerItem {
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
}

.newsInfoInnerItem:last-of-type {
	margin-bottom: 0;
}

.newsInfoInnerItem .newsInfoTitle {
	font-size: 23px;
	font-weight: bold;
	color: #454545;
	margin: 0;
	line-height: 40px;
}

.newsSubInfo {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #A3A3A3;
	font-size: 18px;
	margin-top: 16px;
}