.popInsdList {
	padding: 0 26px;
	box-sizing: border-box;
	height: 30px;
	margin-top: 40px;
	display: inline-block;
}

.examList {
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 26px;
	box-sizing: border-box;
	padding-bottom: 20px;

	.examItem {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
		border-radius: 20px;
		box-sizing: border-box;
		padding: 20px;
		margin-top: 26px;

		img {
			width: 100%;
			height: 306px;
			border-radius: 20px;
		}

		.examInner {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			flex-direction: column;
			margin-top: 26px;

			.icon {
				margin-right: 10px;
			}

			.examTitle {
				font-size: 28px;
				font-weight: bold;
				color: #5a5a5a;
				margin: 0;
			}

			.examAddress {
				margin: 0;
				margin-top: 10px;
				font-size: 20px;
				font-weight: bold;
				color: #8c9abd;
			}

			.examDate {
				margin: 0;
				margin-top: 10px;
				font-size: 20px;
				color: #b9b9b9;
			}

			.examBtn {
				width: 200px;
				height: 62px;
				background: #4096f7;
				border-radius: 200px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #fff;
				font-weight: bold;
				font-size: 24px;
				cursor: pointer;
			}

			.examBtnInfo {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: center;
			}

			.examSurplus {
				color: #8a8a8a;
				font-size: 24px;
				margin-top: 6px;
			}
		}

		.examSurvey {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			margin-top: 26px;
			border-top: 1px solid #eee;
			padding-top: 26px;

			.examSurveyItem {
				flex-shrink: 0;
				margin-bottom: 20px;
				color: #6f6f6f;
				display: flex;
				justify-content: flex-start;
				align-items: center;

				span {
					color: #4096f7;
					font-weight: bold;
				}
			}
		}
	}
}