html,
body {
  background: #fdfdfd;
}
a {
  text-decoration: none;
}
p {
  margin: 0;
}
.asd {
  height: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999;
}
.loadingPage {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loadingPage p {
  margin-top: 30px;
  color: #717171;
}
