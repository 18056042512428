@import url("../../pages/exam/exam.css");
.examBox {
  height: 100%;
  overflow-y: auto;
  padding: 16px 26px 46px 26px !important;
}
.examinerStore {
  padding-top: 74px;
}

.adm-tabs-tab {
  font-size: 26px;
}

.topTabs {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 74px;
  z-index: 6;
}
.examinerList {
  padding: 26px;
  width: 100%;
  box-sizing: border-box;
}
.authRoleItem {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.03);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 20px;
}

.examine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 200px;
}

.examBox {
  padding-bottom: 36px;
}

.examineImg {
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.examineImg img {
  width: 160px;
  height: 100%;
}

.examineData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 100%;
  padding-left: 16px;
  box-sizing: border-box;
}

.examineTitle {
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 14px;
}
.clickLine {
  border-bottom: 1px solid #4096fe;
  color: #4096fe;
  cursor: pointer;
}
.examineInfoItem {
  display: flex;
  flex-direction: column;
}

.examineInfoItem span {
  line-height: 40px;
}

.examineInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
