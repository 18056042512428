.my {
  width: 750px;
  box-sizing: border-box;
  position: relative;
}
.myBg {
  width: 100%;
  height: 500px;
  background: url("../../assets/myBg.png") no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.myCard {
  padding: 20px 0;
  min-height: 276px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
}
.userInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  margin-top: 30px;
}
.userInfo .name {
  margin-top: 20px;
}
.userInfo .head {
  height: 112px;
  width: 112px;
  border-radius: 200px;
  overflow: hidden;
}

.userInfo .head img {
  width: 100%;
  height: 100%;
}
.arrange {
  padding: 0 26px;
}
.arrangeTitle {
  color: #5a5a5a;
  font-size: 22px;
  margin-bottom: 10px;
}
.swiperItem {
  padding: 0 26px;
  width: 100%;
  height: 186px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.01);
  border-radius: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.swiperLeft {
  width: 86px;
  font-size: 86px;
}
.swiperCenter {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 34px;
  box-sizing: border-box;
}
.centerTag {
  font-size: 22px;
  color: #5d5d5d;
}
.centerTitle {
  font-size: 34px;
  font-weight: bold;
  color: #5d5d5d;
  margin-top: 8px;
}
.centerStart {
  font-size: 22px;
  color: #5d5d5d;
  margin-top: 8px;
}
.centerAddress {
  font-size: 22px;
  color: #a3a3a3;
  margin-top: 8px;
}
.swiperRight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #a5a5a5;
  font-size: 0.22rem;
}
.rightStatus {
  margin-top: 10px;
}
.exit {
  width: 242px;
  height: 64px;
  border-radius: 200px;
  border: 2px solid #ff7171;
  font-size: 28px;
  color: #ff5c5c;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
}
.tags {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}
.tagItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #4096fe;
  height: 50px;
  font-size: 22px;
  border-radius: 100px;
  padding: 0 12px;
  color: #fff;
}
.addInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #4096fe;
  height: 50px;
  font-size: 22px;
  border-radius: 100px;
  padding: 0 12px;
  color: #4096fe;
}
.addInfo .icon {
  margin-right: 10px;
}
.addInfo p {
  margin: 0;
}
.myQuickLink p {
  margin: 0;
}
.myQuickLink {
  box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: space-around;
  flex-wrap: wrap;
  width: 698px;
  margin: 0 auto;
  background: #fff;
  padding: 20px 0;
  position: relative;
  z-index: 6;
}
.icon.size {
  font-size: 48px !important;
}
.myQuickLinkItem {
  width: 25%;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.myQuickLinkItem .icon {
  font-size: 55px;
}
.myQuickLinkItem p {
  margin-top: 20px;
  font-size: 24px;
}
.myQuickLinkTitle {
  width: 698px;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 12px;
  box-sizing: border-box;
}
.notLogin {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.notLogin .head {
  font-size: 110px;
  overflow: hidden;
}
.notLogin .name {
  font-size: 30px;
  font-weight: bold;
  margin-top: 10px;
}
.noThingSwiper {
  display: flex;
  justify-content: center;
  align-items: center;
}
